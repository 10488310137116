import React from "react";
import "./Hero.css";
import Lottie from 'react-lottie';
import animationUs from '../../animations/us.json'

class Hero extends React.Component {

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationUs,
      renderer: 'svg'
  }
    return (
        <div className="flex flex-col lg:flex-row" id="hero">
          <div className="lg:w-1/2 p-4">
          <h1>David & Monika</h1>
           <h3>
            {this.props.t("hero.weddingwebsite", {
              framework: "react-i18next"
            })}
          </h3>
          <h3>
            {this.props.t("hero.locationwedding", {
              framework: "react-i18next"
            })}
          </h3> 
          <hr/>
          <h4>
            {this.props.t("hero.itinerary3", {
              framework: "react-i18next"
            })}
          </h4> 
          <h4>
            {this.props.t("hero.itinerary4", {
              framework: "react-i18next"
            })}
          </h4>
          <h4>
            {this.props.t("hero.itinerary6", {
              framework: "react-i18next"
            })}
          </h4>
          <h4>
            {this.props.t("hero.itinerary8", {
              framework: "react-i18next"
            })}
          </h4>
          <h4>
            {this.props.t("hero.itinerary9", {
              framework: "react-i18next"
            })}
          </h4>
        </div>
        <div className="lg:w-1/2 p-4"  id="imagesection">
{/*         <img  className="image"  src="/images/Custom/monikaanddavid.png" alt="David & Monika"/>  
 */}        
        <Lottie
                options={defaultOptions}
                height={500}
                width={500}
               />
        </div>
        </div>
    );
  }
}

export default Hero;
