import React from "react";
import "./RSVPform.css";
import EditRSVPform from "../EditRSVPform/EditRSVPform";

class RSVPform extends React.Component {
  //using the state to toggle the plus one
  state = {
    isChecked: false,
    plusOne: false
  };

  nameRef = React.createRef();
  emailRef = React.createRef();
  attendanceRef = React.createRef();
  allergiesRef = React.createRef();
  guestNameRef = React.createRef();
  guestAllergiesRef = React.createRef();
  roomConfirmationRef = React.createRef();
  questionRef = React.createRef();

  submitForm = e => {
    //stop the form from submitting
    e.preventDefault();
    //getting the input value of the submitForm
    const RSVP = {
      name: this.nameRef.current.value,
      email: this.emailRef.current.value,
      attendance: this.attendanceRef.current.value,
      allergies: this.allergiesRef.current.value,
      userid: this.props.user,
      guestName: this.guestNameRef.current.value,
      guestAllergies: this.guestAllergiesRef.current.value,
      roomConfirmation: this.roomConfirmationRef.current.value,
      question:this.questionRef.current.value
    };

    this.props.addRSVP(RSVP);
    //refresh the submitForm
    e.currentTarget.reset();
  };

  displayPlusOneOptions = () => {
    if (this.state.plusOne) {
      return (
        <div className="guestDetails">
          <input
            name="guestName"
            type="text"
            ref={this.guestNameRef}
            placeholder={this.props.t("plusOne.guestName", {
              framework: "react-i18next"
            })}
          />
          <textarea
            name="guestAllergies"
            ref={this.guestAllergiesRef}
            placeholder={this.props.t("plusOne.guestAllergies", {
              framework: "react-i18next"
            })}
          />

        </div>


      );
    } else {
      return (
        <div style={{ display: "none" }} className="guestDetails">
          <p>
            {this.props.t("plusOne.header", {
              framework: "react-i18next"
            })}
          </p>
          <input
            name="guestName"
            type="text"
            defaultValue=""
            ref={this.guestNameRef}
            placeholder={this.props.t("RSVP.fieldName", {
              framework: "react-i18next"
            })}
          />
          <textarea
            name="guestAllergies"
            defaultValue=""
            ref={this.guestAllergiesRef}
            placeholder={this.props.t("plusOne.guestAllergies", {
              framework: "react-i18next"
            })}
          />
        </div>

      );
    }
  };

  //conditional rendering of the form that evaluates if the object completedForm has properties
  renderForm = () => {
    if (Object.getOwnPropertyNames(this.props.completedForm).length === 1) {
      return (
        <div className="flex flex-col lg:flex-row border-l-200 border-r-200">
          <div className="lg:w-1/2 p-4">
            <div className="register-wrapper section" id="RSVP">
              <h2>
                {this.props.t("RSVP.title", {
                  framework: "react-i18next"
                })}<i className="fas fa-heart" />
              </h2>
              {/*    <p id="headerExplain">
            {this.props.t("RSVP.headerExplain", {
              framework: "react-i18next"
            })}
          </p> */}
              <div className="register-block">
                <form onSubmit={this.submitForm}>
                  <label className="RSVPlabel">Name</label>
                  <input
                    required
                    name="name"
                    type="text"
                    ref={this.nameRef}
                    placeholder={this.props.t("RSVP.fieldName", {
                      framework: "react-i18next"
                    })}
                  />
                  <label className="RSVPlabel">Email address</label>
                  <input
                    required
                    name="email"
                    type="email"
                    ref={this.emailRef}
                    placeholder={this.props.t("loginpage.placeholderEmail", {
                      framework: "react-i18next"
                    })}
                  />
                  <label className="RSVPlabel">Attendance</label>
                  <select required name="attendance" ref={this.attendanceRef}>
                    <label className="RSVPlabel">Attendance - yes</label>
                    <option value="yes">
                      {this.props.t("RSVP.fieldAttendanceYes", {
                        framework: "react-i18next"
                      })}
                    </option>
                    <label className="RSVPlabel">Attendance - no</label>
                    <option value="no">
                      {this.props.t("RSVP.fieldAttendanceNo", {
                        framework: "react-i18next"
                      })}
                    </option>
                  </select>
                  <textarea
                    name="allergies"
                    ref={this.allergiesRef}
                    placeholder={this.props.t("RSVP.fieldAllergies", {
                      framework: "react-i18next"
                    })}
                  />
                  <h3>
                    {this.props.t("RSVP.roomConfirm", {
                      framework: "react-i18next"
                    })}<i className="fas fa-heart" />
                  </h3>
                  <select required name="room" ref={this.roomConfirmationRef}>

                    <label className="RSVPlabel">Room Booking Confirmation - Yes</label>
                    <option value="yes">
                      {this.props.t("RSVP.fieldRoomYes", {
                        framework: "react-i18next"
                      })}
                    </option>
                    <label className="RSVPlabel">Room Booking Confirmation - No</label>
                    <option value="no">
                      {this.props.t("RSVP.fieldRoomNo", {
                        framework: "react-i18next"
                      })}
                    </option>
                  </select>
                  <input
                    required
                    name="question"
                    type="text"
                    ref={this.questionRef}
                    placeholder={this.props.t("RSVP.fieldQuestion", {
                      framework: "react-i18next"
                    })}
                  />

                  <div>
                    <ul id="plusOneOptions">
                      <p id="plusOneText">
                        {this.props.t("plusOne.title", {
                          framework: "react-i18next"
                        })}
                      </p>
                      <div id="radiobuttons">
                        <li>
                          <label>
                            {this.props.t("plusOne.yes", {
                              framework: "react-i18next"
                            })}
                          </label>
                          <input
                            name="plusOne"
                            type="radio"
                            value="yes"
                            onClick={() =>
                              this.setState({ isChecked: true, plusOne: true })
                            }
                          />
                        </li>
                        <li>
                          <label>No</label>
                          <input
                            name="plusOne"
                            type="radio"
                            value="no"
                            onClick={() =>
                              this.setState({ isChecked: true, plusOne: false })
                            }
                          />
                        </li>
                      </div>
                    </ul>
                    {this.displayPlusOneOptions()}
                  </div>
                  <input
                    type="submit"
                    value={this.props.t("RSVP.confirm", {
                      framework: "react-i18next"
                    })}
                  />

                </form>
              </div>

            </div>
          </div>
          <div className="lg:w-1/2 p-4" id="imagesection">

            <img className="image" src="/images/Custom/mountfalcon2.png" alt="Mount Falcon" />

          </div>
        </div>
      );
    } else {
      return (
        <div className="parent" id="parent">
          <div>
            <EditRSVPform
              t={this.props.t}
              user={this.props.user}
              completedForm={this.props.completedForm}
              updateRSVP={this.props.updateRSVP}
              key={this.props.key}
            />
          </div>
          <div id="imagesection">
            <img className="image" src="/images/Custom/mountfalcon2.png" alt="Mount Falcon" />
          </div>
        </div>
      );
    }
  };

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

export default RSVPform;
