//package that mirrors to state
import Rebase from "re-base";
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD9uklUqgY8xopFWc0tqNympxSwAmxmnlk",
  authDomain: "davxmon.firebaseapp.com",
  databaseURL: "https://davxmon-default-rtdb.europe-west1.firebasedatabase.app"
});

//created the firebase binding
const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };

export default base;
