import React from 'react';

import "./Footer.css";
class Footer extends React.Component {

  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer flex justify-between">
        <div className="footer-content">
          <p className="copyright">
            &copy; {currentYear} David X Monika
          </p>
        </div>
        <div className="footer-content">
          <p className="message">
            Thank you for your time and we look forward to celebrating with you <i className="fas fa-hand-peace" />
          </p>
        </div>
      </footer>
    );
  };
};

export default Footer;
