const initialData = {
  david: {
    RSVP: {
      name: "David Finlay",
      email: "david.finlay.1990@gmail.com",
      guests: 0,
      allergies: "No whole nuts in desert",
      music: ""
    },
    username: "daveyros37"
  },
  monika: {
    RSVP: {
      name: "Monika Karaliunaite",
      email: "mon.howl@gmail.com",
      guests: 0,
      allergies: "none",
      music: "Country Road (Take me home)"
    },
    username: "monika37"
  },
  wookie: {
    RSVP: {
      name: "Wookie Wookerson",
      email: "wooks@wooks.com",
      guests: 6,
      allergies: "x",
      music: "x"
    },
    username: "Wookie"
  }
};

export default initialData;
