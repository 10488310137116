import React from "react";
import "./Navbar.css";

//handling the navigation
import { HashLink as Link } from "react-router-hash-link";

//handling the translations
// import { translate, Trans } from "react-i18next";
class Navbar extends React.Component {
  render() {
    return (
      <header className="bg-white border-b border-black-300 fixed top-0 left-0 right-0 z-50">
        <nav className="flex justify-between items-center max-w-7xl mx-auto py-4 px-8">
          <ul className="flex list-none justify-between w-full">
            <li className="mr-6">
              <Link smooth to="#hero">
                <i className="fas fa-clock" />
                <span className="description">
                  {this.props.t("navbar.Itinerary", {
                    framework: "react-i18next"
                  })}
                </span>
              </Link>
            </li>

            <li className="mr-6">
              <Link smooth to="#location">
                <i className="fas fa-map" />
                <span className="description">
                  {this.props.t("navbar.Loction", {
                    framework: "react-i18next"
                  })}
                </span>
              </Link>
            </li>
            <a href="#">
              <div className="flex justify-center items-center">
                <div className="w-32">
                  <img src="/images/Custom/dandm.png" alt="David and Monika" className="w-full h-auto" />
                </div>
              </div>
            </a>
            <li className="mr-6">
              <Link smooth to="#hero">
                <i className="fas fa-fish" />
                <span className="description">
                  {this.props.t("navbar.activities", {
                    framework: "react-i18next"
                  })}
                </span>
              </Link>
            </li>
            <li className="text-gray-900 hover:text-gray-600">
              <Link smooth to="#RSVP">
                <i className="fas fa-pen-nib" />
                <span className="description">
                  {this.props.t("navbar.RSVP", {
                    framework: "react-i18next"
                  })}
                </span>
              </Link>
            </li>
          </ul>

        </nav>
      </header>
    );
  }
}
export default Navbar;

