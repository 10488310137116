import React from "react";

const NotFound = () => (
  <div className="flex flex-col md:flex-row"  id="notfound">
      <div className="lg:w-1/2 p-4"  id="imagesection">
        <img  className="image"  src="/images/Custom/monikaanddavid.png" alt="David & Monika"/>  
        </div>
    <div className="md:w-1/2 p-4">
    <p>ERROR</p>
    <p>Page not found</p>
    <a href="/">
      <button>Back to homepage</button>
    </a>
    </div>
  </div>
);

export default NotFound;
