import React from "react";
import "./LocationCustom.css";
import Lottie from 'react-lottie';
import animationMap from '../../animations/map.json'
class LocationCustom extends React.Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationMap,
      renderer: 'svg'
    }
    return (
      <div className="flex flex-col lg:flex-row" id="location">
        <div className="lg:w-1/2 p-4" id="imagesection">
          {/*           <img src="/images/Custom/ireland.png" className="w-full h-full object-cover" alt="Event" />
 */}    <Lottie
            options={defaultOptions}
            height={500}
            width={500}
          />
        </div>
        <div className="lg:w-1/2 p-4">
          <h2>David & Monika</h2>
          <h3>
            {this.props.t("locationcustom.title", {
              framework: "react-i18next"
            })}
          </h3>
          <h4>
            {this.props.t("locationcustom.locationwedding", {
              framework: "react-i18next"
            })}
          </h4>
        </div>

      </div>

    );
  }
}

export default LocationCustom;
